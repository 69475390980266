import React, { useEffect } from "react";
import { FormControl, Typography } from "@mui/material";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";

import OptionSelect from "../../components/input/OptionSelect";

const tableSets = [
  // MF
  { id: "MFAuroraTableSet", label: "Aurora", study: "mf" },
  // ANN
  { id: "ANNAuroraTableSet", label: "Aurora", study: "ann" },
  { id: "ANNTableSet", label: "MySQL", study: "ann" },
  // SMA
  { id: "SMAAuroraTableSet", label: "Aurora", study: "sma" },

  // ALL
];

const tableNames = [
  // MF
  { id: "exclusion_consortiumdenyoffice", label: "OFFICE EXCLUSIONS - Braavos", study: "mf", table_set: "MFAuroraTableSet" },
  { id: "exclusion_consortiumallowoffice", label: "OFFICE INCLUSIONS - Braavos", study: "mf", table_set: "MFAuroraTableSet" },
  { id: "exclusion_consortiumdenyfirm", label: "BROKER EXCLUSIONS - Braavos", study: "mf", table_set: "MFAuroraTableSet" },
  { id: "exclusion_consortiumallowfirm", label: "BROKER INCLUSIONS - Braavos", study: "mf", table_set: "MFAuroraTableSet" },
  { id: "exclusion_consortiumdenyfirmzip", label: "BROKER ZIP EXCLUSIONS - Braavos", study: "mf", table_set: "MFAuroraTableSet" },
  { id: "exclusion_consortiumallowfirmzip", label: "BROKER ZIP INCLUSIONS - Braavos", study: "mf", table_set: "MFAuroraTableSet" },
  // ANN
  { id: "exclusion_consortiumdenyoffice", label: "OFFICE EXCLUSIONS - Braavos", study: "ann", table_set: "ANNAuroraTableSet" },
  { id: "exclusion_consortiumallowoffice", label: "OFFICE INCLUSIONS - Braavos", study: "ann", table_set: "ANNAuroraTableSet" },
  { id: "exclusion_consortiumdenyfirm", label: "BROKER EXCLUSIONS - Braavos", study: "ann", table_set: "ANNAuroraTableSet" },
  { id: "exclusion_consortiumallowfirm", label: "BROKER INCLUSIONS - Braavos", study: "ann", table_set: "ANNAuroraTableSet" },
  { id: "exclusion_consortiumdenyfirmzip", label: "BROKER ZIP EXCLUSIONS - Braavos", study: "ann", table_set: "ANNAuroraTableSet" },
  { id: "exclusion_consortiumallowfirmzip", label: "BROKER ZIP INCLUSIONS - Braavos", study: "ann", table_set: "ANNAuroraTableSet" },
  { id: "dd_branch_id_channel", label: "DD BRANCH ID CHANNEL - MySQL", study: "ann", table_set: "ANNTableSet" },
  // SMA
  { id: "exclusion_consortiumdenyoffice", label: "OFFICE EXCLUSIONS - Braavos", study: "sma", table_set: "SMAAuroraTableSet" },
  { id: "exclusion_consortiumallowoffice", label: "OFFICE INCLUSIONS - Braavos", study: "sma", table_set: "SMAAuroraTableSet" },
  { id: "exclusion_consortiumdenyfirm", label: "BROKER EXCLUSIONS - Braavos", study: "sma", table_set: "SMAAuroraTableSet" },
  { id: "exclusion_consortiumallowfirm", label: "BROKER INCLUSIONS - Braavos", study: "sma", table_set: "SMAAuroraTableSet" },
  { id: "exclusion_consortiumdenyfirmzip", label: "BROKER ZIP EXCLUSIONS - Braavos", study: "sma", table_set: "SMAAuroraTableSet" },
  { id: "exclusion_consortiumallowfirmzip", label: "BROKER ZIP INCLUSIONS - Braavos", study: "sma", table_set: "SMAAuroraTableSet" },
  // ALL
];

export default function GenericExporterForm({ children, taskConfig, setTaskConfig, submitHandler, studyType, showTableDropDown = true }) {
  useEffect(() => {
    setTaskConfig((preConfig) => ({ ...preConfig, study: studyType }));
  }, [setTaskConfig, studyType]);

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
      validator={validator}
    >
      <Typography variant="h6">
        {studyType.toUpperCase()}
        {" Generic Exporter "}
      </Typography>
      {showTableDropDown && (
        <FormControl fullWidth margin="normal">
          <OptionSelect
            required
            style={{ width: 500, marginBottom: -10 }}
            value={taskConfig.table_set || ""}
            onChange={(name) => {
              setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, table_set: name }));
            }}
            options={tableSets.filter((o) => o.study === studyType)}
            label="Table Set"
          />
        </FormControl>
      )}
      {showTableDropDown && (
        <FormControl fullWidth margin="normal">
          <OptionSelect
            required
            style={{ width: 500, marginBottom: -10 }}
            value={taskConfig.table_name || ""}
            onChange={(name) => {
              setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, table_name: name }));
            }}
            options={tableNames.filter((o) => o.study === studyType && o.table_set === taskConfig.table_set)}
            label="Table"
            disabled={!taskConfig.table_set}
          />
        </FormControl>
      )}
      {children}
    </Form>
  );
}
