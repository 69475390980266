import React, { useCallback, useState } from "react";
import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";

import { generateDDFirmColumns, generateCommonColumns } from "../../clients/profiles/exclusions/ColumnsDD";
import ExclusionsTable from "./ExclusionsTable";
import FirmZipFilters from "./FirmZipFilters";
import OfficeFilters from "./OfficeFilters";

export default function Exclusions({ study }) {
  const [success, setSuccess] = useState(null);
  const [response, setResponse] = useState("");
  const apiCallback = useCallback((apiSuccess, result) => {
    setSuccess(apiSuccess);
    if (apiSuccess) {
      if (result?.response?.data) {
        setResponse(JSON.stringify(result.response.data));
      } else {
        setResponse("Saved Successfully");
      }
    } else {
      setResponse(JSON.stringify(result.response.data));
    }
  }, []);
  return (
    <div>
      <div style={{ padding: 20 }}>
        <ExclusionsTable
          study={study}
          direction="deny"
          title="Broker and Broker/Zip Exclusions"
          url={`lms/api/v1/consortium/study/${study}/read_exclusions`}
          editUrl={`lms/api/v1/consortium/study/${study}/exclusions`}
          columns={[
            ...generateDDFirmColumns(),
            { header: "Updated By", accessorKey: "updated_by", enableEditing: false },
            { header: "Updated At", accessorKey: "updated_at", enableEditing: false, type: "datetime" },
          ]}
          apiCallback={apiCallback}
          filterPanel={FirmZipFilters}
        />
      </div>
      <div style={{ padding: 20 }}>
        <ExclusionsTable
          study={study}
          direction="allow"
          title="Broker and Broker/Zip Inclusions"
          url={`lms/api/v1/consortium/study/${study}/read_inclusions`}
          editUrl={`lms/api/v1/consortium/study/${study}/inclusions`}
          columns={[
            ...generateDDFirmColumns(),
            { header: "Updated By", accessorKey: "updated_by", enableEditing: false },
            { header: "Updated At", accessorKey: "updated_at", enableEditing: false, type: "datetime" },
          ]}
          apiCallback={apiCallback}
          filterPanel={FirmZipFilters}
        />
      </div>
      <div style={{ padding: 20 }}>
        <ExclusionsTable
          study={study}
          direction="deny"
          title="Office Exclusions"
          url={`lms/api/v1/consortium/study/${study}/read_deny_office`}
          editUrl={`lms/api/v1/${study}/exclusion/${study}_exclusion_consortiumdenyoffice`}
          columns={[
            {
              header: "DD Physical Branch ID",
              accessorKey: "dd_physical_branch_id",
              editable: "always",
            },
            ...generateCommonColumns(),
          ]}
          filterPanel={OfficeFilters}
          apiCallback={apiCallback}
        />
      </div>
      <div style={{ padding: 20 }}>
        <ExclusionsTable
          study={study}
          direction="allow"
          title="Office Inclusions"
          url={`lms/api/v1/consortium/study/${study}/read_allow_office`}
          editUrl={`lms/api/v1/${study}/exclusion/${study}_exclusion_consortiumallowoffice`}
          columns={[
            {
              header: "DD Physical Branch ID",
              accessorKey: "dd_physical_branch_id",
              editable: "always",
            },
            ...generateCommonColumns(),
          ]}
          filterPanel={OfficeFilters}
          apiCallback={apiCallback}
        />
      </div>
      <Snackbar
        open={success !== null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <div>
          <Alert onClose={() => setSuccess(null)} severity={success ? "success" : "error"}>
            {response}
          </Alert>
        </div>
      </Snackbar>
    </div>
  );
}
