import React from "react";
import { Typography } from "@mui/material";
import * as Yup from "yup";

import LMSCheckbox from "../../../components/input/Formik/base/LMSCheckBox";
import { LMSForm } from "../common/LMSForm";
import AnnReportSection, { AnnReportSchema, AnnReportInitialValues } from "../common/ann/AnnReportSection";
import OTTTypeSection, { OTTTypeSchema } from "../common/sections/OTTTypeSection";
import TerritorySection from "../common/sections/TerritorySection";

export const AnnOTTSchema = Yup.object().shape({
  ...AnnReportSchema,
  ...OTTTypeSchema,
});

const AnnOTTInitialValues = {
  time_period: 4,
  ...AnnReportInitialValues,
};

export default function AnnOTTFormDD({
  taskLabel,
  client,
  studyType,
  step,
  setStep,
  submitHandler,
  taskConfig,
  title,
}) {
  return (
    <LMSForm
      taskLabel={taskLabel}
      client={client}
      studyType={studyType}
      step={step}
      setStep={setStep}
      submitHandler={submitHandler}
      taskConfig={taskConfig}
      validationSchema={AnnOTTSchema}
      initialValues={AnnOTTInitialValues}
      title={title}
    >
      <Typography variant="h4" style={{ backgroundColor: "orange" }}>Discovery Data</Typography>
      <AnnReportSection />
      <TerritorySection
        territorySettingsName="territory_settings"
        alternateTerrLabel1="Change Territory Column Grouping"
        alternateTerrGroupingLabel="Change Channel Column Grouping"
      />
      <OTTTypeSection isDDJob />
      <LMSCheckbox
        name="include_indirect_transactions"
        label="Include Indirect Transactions (Previously misnamed Sales)"
      />
      <LMSCheckbox
        name="omit_territory_column"
        label="Omit Territory Column (Previously Exclude Territory Column)"
      />
      <LMSCheckbox
        name="omit_metro_column"
        label="Omit Metro Column (Previously Exclude Metro Column)"
      />
      <LMSCheckbox
        name="omit_zone_column"
        label="Omit Zone Column (Previously Exclude Zone Column)"
      />
      <LMSCheckbox
        name="exclude_channel_col"
        label="Exclude Channel Column"
      />
    </LMSForm>
  );
}
