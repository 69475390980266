import React, { useCallback, useState, useRef } from "react";
import _ from "lodash";
import MaterialTable from "../../../components/Table";

import { API, generateTableCallbacks } from "../../../utils/api";
import { generateExclusionTableCallbacks } from "../../clients/profiles/exclusions/Columns";

export default function ExclusionsTable({ study, direction, url, editUrl, columns, title, apiCallback, filterPanel, genericEditActions = false }) {
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [pagination, setPagination] = useState({
    pageSize: 5,
    pageIndex: 0,
  });
  const mountedRef = useRef(true);
  const getData = useCallback((params = null) => {
    setLoading(true);
    API.request({
      url,
      method: genericEditActions ? "GET" : "POST",
      data: {
        page_idx: params.page_idx,
        limit: params.limit,
        filter_by: {
          ..._.pickBy(params?.filter_by, (value) => value !== ""),
          firm_name: params?.filter_by?.firm_name ? `~${params.filter_by.firm_name}` : undefined,
        },
      },
      params: {
        page_idx: params.page_idx,
        limit: params.limit,
        filter_by: {
          ..._.pickBy(params?.filter_by, (value) => value !== ""),
          firm_name: params?.filter_by?.firm_name ? `~${params.filter_by.firm_name}` : undefined,
        },
      },
    })
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch(() => {
        if (!mountedRef.current) return;
        const rows = [];
        setData({
          rows,
          total: 50,
        });
        setLoading(false);
      });
  }, [genericEditActions, url]);

  const stableColumns = columns.map((column) => ({ ...column }));

  return (
    <div style={{ width: "100%" }}>
      {React.createElement(
        filterPanel,
        {
          filters,
          setFilters,
          getData: () => { setTimeout(setPagination({ pageSize: pagination.pageSize, pageIndex: 0 })); getData({ page_idx: 0, limit: pagination.pageSize, filter_by: filters }); },
          handleResetFilters: () => {
            setFilters((prevFilters) => _.mapValues(prevFilters, () => ""));
            setTimeout(setPagination({ pageSize: pagination.pageSize, pageIndex: 0 })); getData({ page_idx: 0, limit: pagination.pageSize });
          },
        },
      )}
      <MaterialTable
        columns={stableColumns}
        data={data?.rows || []}
        title={title}
        manualPagination
        state={{
          pagination,
          isLoading: loading,
        }}
        rowCount={data?.total}
        onPaginationChange={(updater) => {
          const newPage = updater(pagination);
          getData({ page_idx: newPage.pageIndex, limit: newPage.pageSize, filter_by: filters });
          setPagination(newPage);
        }}
        editable={
          genericEditActions
            ? generateTableCallbacks(
              editUrl || url,
              null,
              null,
              (success, response) => {
                apiCallback(success, response);
                setTimeout(setPagination({ pageSize: pagination.pageSize, pageIndex: 0 }), 0); getData({ page_idx: 0, limit: pagination.pageSize, filter_by: filters });
              },
              setLoading,
            )
            : generateExclusionTableCallbacks(
              editUrl || url,
              apiCallback,
              () => { setTimeout(setPagination({ pageSize: pagination.pageSize, pageIndex: 0 }), 0); getData({ page_idx: 0, limit: pagination.pageSize, filter_by: filters }); },
              "consortium",
              direction,
              study,
              data?.rows,
              setLoading,
            )
        }
      />
    </div>
  );
}
