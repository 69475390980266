import React from "react";
import { Grid, TextField, Button, Card, CardActions, CardContent } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  column: {
    flexBasis: "50%",
    margin: theme.spacing(1),
  },
  action: {
    justifyContent: "left",
  },
}));

export default function FirmZipFilters({ filters, setFilters, getData, handleResetFilters }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Firm Name"
                name="firm_name"
                onChange={(event) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    firm_name: event.target.value,
                  }));
                }}
                value={filters?.firm_name}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Firm CRD"
                name="firm_crd"
                onChange={(event) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    firm_crd: event.target.value,
                  }));
                }}
                value={filters?.firm_crd}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Zip Code"
                name="zip_code"
                onChange={(event) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    zip_code: event.target.value,
                  }));
                }}
                value={filters?.zip_code}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button variant="outlined" color="secondary" onClick={getData}>Apply Filter</Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleResetFilters}
          >
            Reset Filter
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}
